import { default as indexIKaC0ATntZMeta } from "/codebuild/output/src1812461112/src/src/pages/[companySlug]/faq/[id]/index.vue?macro=true";
import { default as indexf8UhYjXsQkMeta } from "/codebuild/output/src1812461112/src/src/pages/[companySlug]/important-news/index.vue?macro=true";
import { default as indexhNhjFGOcJPMeta } from "/codebuild/output/src1812461112/src/src/pages/[companySlug]/index.vue?macro=true";
import { default as indexNdopKD8SF0Meta } from "/codebuild/output/src1812461112/src/src/pages/[companySlug]/news/[id]/index.vue?macro=true";
import { default as indexJ6ar7y6wlSMeta } from "/codebuild/output/src1812461112/src/src/pages/[companySlug]/news/index.vue?macro=true";
import { default as indexlY89soHzx2Meta } from "/codebuild/output/src1812461112/src/src/pages/[companySlug]/preview/faq/index.vue?macro=true";
import { default as indexJaYxAI5SbYMeta } from "/codebuild/output/src1812461112/src/src/pages/[companySlug]/preview/news/index.vue?macro=true";
export default [
  {
    name: "companySlug-faq-id",
    path: "/:companySlug()/faq/:id()",
    component: () => import("/codebuild/output/src1812461112/src/src/pages/[companySlug]/faq/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-important-news",
    path: "/:companySlug()/important-news",
    component: () => import("/codebuild/output/src1812461112/src/src/pages/[companySlug]/important-news/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug",
    path: "/:companySlug()",
    component: () => import("/codebuild/output/src1812461112/src/src/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-news-id",
    path: "/:companySlug()/news/:id()",
    component: () => import("/codebuild/output/src1812461112/src/src/pages/[companySlug]/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-news",
    path: "/:companySlug()/news",
    component: () => import("/codebuild/output/src1812461112/src/src/pages/[companySlug]/news/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-preview-faq",
    path: "/:companySlug()/preview/faq",
    component: () => import("/codebuild/output/src1812461112/src/src/pages/[companySlug]/preview/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "companySlug-preview-news",
    path: "/:companySlug()/preview/news",
    component: () => import("/codebuild/output/src1812461112/src/src/pages/[companySlug]/preview/news/index.vue").then(m => m.default || m)
  }
]